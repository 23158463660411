/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        $(window).load(function(){
          var browserW = $(window).width();
          $(document).foundation();
          $(document).on("scroll", onScroll);
          $(".lazy").lazyload({
            effect : "fadeIn"
          });
          //Setup the plugin, see readme for more examples
          var example = $("#weatherinfo").flatWeatherPlugin({
            location: "Brooklyn, NY", //city and region *required 
            country: "USA",         //country *required 
            //optional:
            api: "openweathermap", //default: yahoo (openweathermap or yahoo)
            apikey: "dd1477319fed7e01fb99e7481832621e",   //api key required for openweather  
            //visit openweathermap.org and sign up for an account to get an api key
            view : "simple", //default: full (partial, full, simple, today or forecast)
            lang: "en", //default: en only openweathermap supports translation (see below for supported languages)
            timeformat: "24", //"24" or "12" default: "12"
            displayCityNameOnly : true, //default: false (true/false) if you want to display only city name
            forecast: 0, //default: 5 (0 -5) how many days you want forecast
            render: true, //default: true (true/false) if you want plugin to generate markup
            loadingAnimation: true, //default: true (true/false) if you want plugin to show loading animation
            units : "imperial"
          });
          
          setTimeout(function(){
            var cloudiness = parseInt($('.cloudiness').text());
            var sunrise = $('.sunrise').text();
            var sunrisetime = parseInt(sunrise.slice(0,2));
            sunrisetime = sunrisetime + 2;

            var sunset = $('.sunset').text();
            var sunsettime = parseInt(sunset.slice(0,2));
            sunsettime = sunsettime - 2;

            //var now = jQuery.now().format("H:MM");
            //nowtime = parseInt(now.slice(0,10));
            var now = parseInt(new Date().getHours());

            //console.log("now " + now, "sunrise " + sunrisetime, "sunset " + sunsettime);
            
            //sunny and day time
            if(cloudiness <= 75 && now > sunrisetime && now < sunsettime){
              $('body').addClass('gray');
              //console.log('gray');
            }
          }, 500);

          $('.btn_down_home').click(function(){
            var browserH = $(window).height() - 60;
            $('body, html').animate({"scrollTop" : browserH}, 800);
          });
          $('ul.nav li a, ul.mobilenav li a').click(function(e){
            e.preventDefault();
            var destination = $(this).attr('href');
            var pos = $(destination).offset();
            $('ul.nav li').removeClass('active');
            $(this).parent('li').addClass('active');
            $('body, html').animate({'scrollTop' : pos.top}, 1000);
            $('.hidden_nav').fadeOut();
            $('.btn_menu').removeClass('close');
          });

          maps();

          $('ul.plan_nav li').click(function(){
            $('ul.plan_nav li').removeClass('active');
            $(this).addClass('active');
            var thisRel = $(this).attr('rel');
            $('.plans img.plan').fadeOut();
            if(thisRel == "core"){
              $('img.core').fadeIn();
            }else if(thisRel == "open"){
              $('img.openoffice').fadeIn();
            }else if(thisRel == "high"){
              $('img.highdensity').fadeIn();
            }else{
              $('img.officeintensive').fadeIn();
            }
          });

          $("#lightgallery").lightGallery();

          $('.btn_menu').click(function(){
            if($(this).hasClass('close')){
              $(this).removeClass('close');
              $('.hidden_nav').fadeOut(800);
            }else{
              $(this).addClass('close');
              $('.hidden_nav').fadeIn(800);
            }
          });

          $('.availability_list .list').click(function(){
            var thisPos = $('.plan_wrapper').offset();
            $('body, html').animate({'scrollTop' : thisPos.top-100});
          });
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  var stylesArray = [
    {
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "2.00"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#9c9c9c"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7b7b7b",
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff",
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffe15f"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#efd151"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c8d7d4"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#070707"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }
  ];
  var map;
  var mapDefaultStyle = false;
  var latlng;
  var customOverlay;
  //Markers Array
  var allmarkers = [];
  //XML Variable
  var xmlUrl;
  var infoWindow = new google.maps.InfoWindow();
  var geocoder;
  var currentSection;
  //for map navigation
  var thisIndex;
  var browserW = $(window).width();
  var browserH = $(window).height();
  var navH;
  var thisCat;
  var html;
  var sitePath = "http://ifstudiony.com/sites/n6lofts/wp-content/themes/N6Lofts/assets/";
  var markers_cat = $('ul.mapmarkers_hide li');
  var transitOverlay;

  function maps(){
    initialize();
    $('ul.mapnav li').eq(0).trigger('click');
    function initialize(){
        var zoom;
        if(browserW > 667){
          zoom = 16;
        }else{
          zoom = 15;
        }
        var minzoom;
        transitLayer = new google.maps.TransitLayer();

        latlng = new google.maps.LatLng(40.716265, -73.955489);
        var mapOptions = {
            center: latlng,
            zoom: zoom,
            maxZoom: 16,
            minZoom: 14,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            panControl: false,
            zoomControl: true,
            streetViewControl: false,
            scaleControl: false,
            overviewMapControl: false,
            mapTypeControl: false,
            scrollwheel: false
        };

        map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);

        // Add Map 
        if(!mapDefaultStyle)map.setOptions({styles: stylesArray});
        var iconBase = {
            url: sitePath + 'images/map_n6lofts.png',
            size: new google.maps.Size(120,103),
            scaledSize: new google.maps.Size(60,51),
            origin: new google.maps.Point(0,0),
            anchor: new google.maps.Point(40,40)
        };
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(40.714503, -73.953434),
            map: map,
            icon: iconBase,
            zIndex: 98
        });

        $('ul.mapnav li').click(function(){
          var thisID = $(this).html();

          $('ul.mapnav li').removeClass('active');
          $(this).addClass('active');
          clearMarkers();

          if(thisID == "amenities map"){
            //console.log('google maps in');
            $('#map-canvas, ul.markers').fadeIn();
            $('.mapmarkers, img.transit').fadeOut();

            transitLayer.setMap(null);
            markers_cat = $('ul.markers li');
            //console.log(markers_cat.length);

            allmarkers = allmarkers || [];
            for(var i = 0; i < markers_cat.length; i++){
              //console.log(markers_cat.length);
              var cat = markers_cat[i].getAttribute("data-cat");
              var name = markers_cat[i].getAttribute("data-name");
              var latitude = markers_cat[i].getAttribute("data-latitude");
              var longitude = markers_cat[i].getAttribute("data-longitude");
              var address = markers_cat[i].getAttribute("data-address");
              var website = markers_cat[i].getAttribute("data-website");
              var phone = markers_cat[i].getAttribute("data-phone");
              var image = markers_cat[i].getAttribute("data-image");
              var html;
              var markerpath;
              //console.log(i, name, latitude, longitude);
              if(image != ""){
                html = "<div class='infow'><div>" + name + "</div><img src='" + image + "'><br>" + address + "<br>" + phone + "<br>" + website +  "</div>";
              }else{
                html = "<div class='infow'><div>" + name + "</div>" + address + "<br>" + phone + "<br>" + website +  "</div>";
              }
              
              switch(cat){
                case "food":
                  markerpath = sitePath + "images/marker_food.png";
                break;
                case "restaurants":
                  markerpath = sitePath + "images/marker_restaurants.png";
                break;
                case "retail":
                  markerpath = sitePath + "images/marker_retail.png";
                break;
                case "entertainment":
                  markerpath = sitePath + "images/marker_entertainment.png";
                break;
                case "spa":
                  markerpath = sitePath + "images/marker_spa.png";
                break;
              }


              var markerimg = {
                url: markerpath,
                size: new google.maps.Size(24, 24),
                scaledSize: new google.maps.Size(12,12),
                origin: new google.maps.Point(0,0),
                anchor: new google.maps.Point(12, 12)
              };
              var marker = new google.maps.Marker({
                  position: new google.maps.LatLng(latitude, longitude),
                  map: map,
                  icon: markerimg,
                  title: name,
                  zIndex: 99
              });
              allmarkers.push(marker);
              bindInfoWindow(marker, map, infoWindow, html);
            }
            $('.marker_title').click(function(){
              $('ul.cat').slideUp();
              $(this).siblings('ul.cat').slideDown();
            });
            $('.marker_title').eq(0).trigger('click');
          }else{
            //console.log('google maps out');
            $('#map-canvas, ul.markers').fadeOut();
            $('.mapmarkers, img.transit').fadeIn();
            /*transitLayer.setMap(map);
            markers_cat = $('ul.mapmarkers_hide li');

            allmarkers = allmarkers || [];
            for(var i = 0; i < markers_cat.length; i++){
              var name = markers_cat[i].getAttribute("data-name");
              var latitude = markers_cat[i].getAttribute("data-latitude");
              var longitude = markers_cat[i].getAttribute("data-longitude");
              var html;
              //console.log(i, name, latitude, longitude);
              html = "<div class='infow'>" + name + "</div>";

              if(name == "L"){
                var markerimg = {
                  url: sitePath + "images/marker_l.svg",
                  size: new google.maps.Size(18, 18)
                };
              }else if(name == "G"){
                var markerimg = {
                  url: sitePath + "images/marker_g.svg",
                  size: new google.maps.Size(18, 18)
                };
              }else if(name == "J"){
                var markerimg = {
                  url: sitePath + "images/marker_j.svg",
                  size: new google.maps.Size(18, 18)
                };
              }else if(name == "M"){
                var markerimg = {
                  url: sitePath + "images/marker_m.svg",
                  size: new google.maps.Size(18, 18)
                };
              }else if(name == "Z"){
                var markerimg = {
                  url: sitePath + "images/marker_z.svg",
                  size: new google.maps.Size(18, 18)
                };
              }
              var marker = new google.maps.Marker({
                  position: new google.maps.LatLng(latitude, longitude),
                  map: map,
                  icon: markerimg,
                  title: name,
                  zIndex: 99
              });
              allmarkers.push(marker);
              bindInfoWindow(marker, map, infoWindow, html);
            }*/
          }
        });
        
        $( ".markers li").click(function(){
          //var thisIndex = $(this).index();
          var thisIndex = $(this).index('.markers li');
          /*if(thisIndex <= 15){
            thisIndex -= 0;
          }else if(thisIndex >= 17 && thisIndex <= 26){
            thisIndex -= 1;
          }else if(thisIndex >= 28 && thisIndex <= 38){
            thisIndex -= 2;
          }else if(thisIndex >= 40 && thisIndex <= 49){
            thisIndex -= 3;
          }else if(thisIndex >= 51){
            thisIndex -= 4;
          }*/
          //console.log(thisIndex);
          $('.markers li').removeClass('active');
          $(this).addClass('active');
          google.maps.event.trigger(allmarkers[thisIndex], "click");
        });
    }
    google.maps.event.addDomListener(window, 'load', initialize);
  }

  //loadMarkers();
  function loadMarkers(){

  }

  function bindInfoWindow(marker, map, infoWindow, html){
    google.maps.event.addListener(marker, 'click', function() {
        var infowindow = new google.maps.InfoWindow({
            content: html
        });
        //map.panTo(marker.getPosition());
        infoWindow.setContent(html);
        infoWindow.open(map, marker);
        //$('.markers div').removeClass('active');
    });
  }

  function clearMarkers(){
    //console.log(markers.length);
    for (var i = 0; i < allmarkers.length; i++) {
        //map.markers.pop().setMap(null);
        allmarkers[i].setMap(null);
        //console.log(i, allmarkers[i]);
    }
    //allmarkers = [];
    allmarkers.length = 0;
    //console.log(l,'clear markers');
  }
  function onScroll(event){
    var scrollPos = $(document).scrollTop();
    $('ul.nav li a').each(function () {
        var currLink = $(this);
        var currLi = $(this).parent('li');
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top-100 <= scrollPos && refElement.position().top-100 + refElement.height() > scrollPos) {
            $('ul.nav li').removeClass("active");
            currLi.addClass("active");
        }
        else{
            currLi.removeClass("active");
        }
    });
  }

})(jQuery); // Fully reference jQuery after this point.
